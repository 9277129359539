@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.HeaderMain{
  width: full;
  background-color: #ed1c24;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}



.footprint-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  
  max-width: 600px; /* Adjust the width as needed */
  margin: auto;
}

.footprint-content {
  display: flex;
  justify-content: space-between;
}

.footprint-text {
  flex-basis: 55%; /* Adjust text area width */
  margin-left: 2%;
  text-align: left;
  
  
   /* Adjust spacing between text and image */
 
}

.footprint-text h2 {
  
  font-size: 24px; /* Adjust font size as needed */
  color:black; /* Adjust the color to match the heading */
  margin-bottom: 10px;
  
  
}

.footprint-text p {
  font-size: 16px; /* Adjust the font size as needed */
  line-height: 1.5; /* Adjust line height for better readability */
  color: black; /* Adjust the color to match the paragraph */
  text-align: justify;
  word-spacing: -1px;
}

.footprint-image {
  flex-basis: 40%; /* Adjust image area width */
  justify-content: right; /* Aligns the image to the right */
  display: flex;
  align-items: center;
  
  
}

.footprint-image img {
  max-width: 100%;
  margin-right: 2%;
}



@media (max-width: 768px) {
  .footprint-text p {
    font-size: 14px; /* Adjust the font size as needed */
    word-spacing: -3px;
    width: 98%;
   
    
  }

  .footprint-text {
    
    margin-left: 0;
    font-size: 19px;
    word-spacing: -1px;
     /* Adjust spacing between text and image */
   
  }
  .footprint-text h2 {
    font-size: 16px;
    white-space: nowrap; /* Adjust the size as needed to fit on one line */
  }

 

  
  
}

.headingPipeLine{
color: white;
}

.HeaderMain2{
  width: full;
  margin-top: 4%;
  /* background-color: #ed1c24; */
  background-color: #b1b7c3;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.HeaderMain22{
  width: full;
  margin-top: 4%;
  /* background-color: #ed1c24; */
  background-color:green;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.spin-button {
  background-color: red;
  color: white;
  border-color: red; 
 
  width: 20px; /* Set explicit width */
  height: 24px; /* Set explicit height */
  line-height: 14px; /* Align content vertically in the center */
  text-align: center; /* Align content horizontally in the center */
  font-size: 16px; /* Adjust font size if necessary */
  padding: 0; /* Remove any default padding */
}

/* Hide the default spin button */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  -moz-appearance: textfield;
  font-size: 17px;
  width: 170px;
}



.sizeDiv{
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

 
}

.TableMainDiv{
  width: full;
  display: flex;
  justify-content: center;
  align-items: center;
  
 
 
}

.MainTable{
  width: 90%;
}


.TableMainDiv2{
  width: full;
  display: flex;
  justify-content: center;
  align-items: center;
  
 
 
}

.grey-button {
  background-color: #b1b7c3; /* Grey background color */
  color: white; /* White text color */
  border: none; /* No border */
  padding: 15px 40px; /* Padding around text */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  font-size: 20px; /* Font size */
  float: left; 
  margin-left: 5%;
  font-weight: bolder;
}

.grey-button:hover {
  background-color: #999; /* Darker grey color on hover */
}

.buttondiv{
  width: 100%;
  
  
  height: 6vh;
  
  
}
/* .Footer {
  margin-top: 2%;
  text-align: left; 
  width: 100%;
  margin-bottom: 10%;
  
  
} */

.Footer {
  margin-top: 2%;
  text-align: left; /* Align content to the left */
  max-width: calc(100% - 180px); /* Set maximum width with space on both sides */
  width: 100%;
  margin-bottom: 10%;
  padding-left: 90px; /* Add space on the left side */
  padding-right: 90px; /* Add space on the right side */
}

.Footer2 {
  margin-top: 2%;
  text-align: left; /* Align content to the left */
  max-width: calc(100% - 180px); /* Set maximum width with space on both sides */
  width: 30%;
  margin-bottom: 2%;
  padding-left: 90px; /* Add space on the left side */
  padding-right: 90px; /* Add space on the right side */
 font-weight: bold;
}

.Footer p{
  
  font-size: 18px;
  margin-top: 10px;
  text-align: left;
  word-spacing: -1px;
  text-align: justify; 
  }

.Footer2 p{
  
  font-size: 18px;
  margin-top: 10px;
  text-align: left;
  text-align: justify; 
}

.custom-link {
  text-decoration: none;
  color: white;
 
}

.number-input {
  border: 1px solid #b1b7c3; /* Change the color code to the desired color */
  word-spacing: -1px;
}

@media (max-width: 768px) {

  .Footer {
    
    max-width: calc(100% - 60px); /* Set maximum width with space on both sides */
    
    margin-top: 8vh;
    padding-left: 30px; /* Add space on the left side */
    padding-right: 30px; /* Add space on the right side */
    
    
  }
  .Footer p{
  
    font-size: 17px;
    text-align: left; 
   
   
    
  }

  
}



/* styles.css */

.newDiv{
  width:90%;
  padding-left: 5%;
  padding-right: 5%;
  height: 15vh;
 

}

.info-component {
  width: 100%;
  text-align: left;
  margin-top: 3%;
    margin-bottom: 10%;
    font-weight: bold;
    

}

/* Large screens */
@media (min-width: 768px) {
  .info-component {
    width: 30%;
    font-size: 20px;
    font-weight: bold;
    
  }
}
